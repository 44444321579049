import styled, { css, keyframes } from "styled-components";
import Hill from "../../assets/illustrations/checkpoints/hill.svg";
import Mountain from "../../assets/illustrations/checkpoints/mountain.svg";
import Farm from "../../assets/illustrations/checkpoints/farm.svg";
import Castle from "../../assets/illustrations/checkpoints/castle.svg";
import Factory from "@assets/illustrations/checkpoints/factory.svg";
import Lighthouse from "../../assets/illustrations/checkpoints/lighthouse.svg";
import City from "../../assets/illustrations/checkpoints/city.svg";
import Truck from "../../assets/illustrations/checkpoints/truck.svg";
import Boat from "../../assets/illustrations/checkpoints/boat.svg";
import Sea from "../../assets/illustrations/checkpoints/sea.svg";


import HillInactive from "../../assets/illustrations/checkpoints/hill_inactive.svg";
import DesertInactive from "../../assets/illustrations/checkpoints/desert_inactive.svg";
import FarmInactive from "../../assets/illustrations/checkpoints/farm_inactive.svg";
import CastleInactive from "../../assets/illustrations/checkpoints/castle_inactive.svg";
import FactoryInactive from "@assets/illustrations/checkpoints/factory_inactive.svg";

import LighthouseInactive from "../../assets/illustrations/checkpoints/lighthouse_inactive.svg";
import CityInactive from "../../assets/illustrations/checkpoints/city_inactive.svg";
// import Truck from "../../assets/illustrations/checkpoints/truck.svg";
// import Boat from "../../assets/illustrations/checkpoints/boat.svg";
// import Sea from "../../assets/illustrations/checkpoints/sea.svg";

import FactoryLine from "../../assets/illustrations/checkpoints/line.svg";
import GreenTruck from "../../assets/illustrations/welcome/green_truck.svg";
import Smoke from "../../assets/illustrations/checkpoints/smoke.svg";
import Sun from "../../assets/illustrations/checkpoints/sun.svg";
import Sky from "../../assets/illustrations/checkpoints/sky.svg";
import Sky2 from "../../assets/illustrations/checkpoints/sky_2.svg";
import Sky2Inactive from "../../assets/illustrations/checkpoints/sky_2_inactive.svg";
import Sky3 from "../../assets/illustrations/checkpoints/sky_3.svg";
import Sky3Inactive from "../../assets/illustrations/checkpoints/sky_3_inactive.svg";
import Sky4 from "../../assets/illustrations/checkpoints/sky_4.svg";
import Sky4Inactive from "../../assets/illustrations/checkpoints/sky_4_inactive.svg";
import Sky5 from "../../assets/illustrations/checkpoints/sky_5.svg";
import Sky5Inactive from "../../assets/illustrations/checkpoints/sky_5_inactive.svg";
import Sky6 from "../../assets/illustrations/checkpoints/sky_6.svg";
import Sky6Inactive from "../../assets/illustrations/checkpoints/sky_6_inactive.svg";
import Sky7 from "../../assets/illustrations/checkpoints/sky_7.svg";
import Sky7Inactive from "../../assets/illustrations/checkpoints/sky_7_inactive.svg";
import Sky8 from "../../assets/illustrations/checkpoints/sky_8.svg";
import Sky9 from "../../assets/illustrations/checkpoints/sky_9.svg";
import Sky8Inactive from "../../assets/illustrations/checkpoints/sky_8_inactive.svg";
import Truck2 from "../../assets/illustrations/checkpoints/truck2.svg";

export const StyledHill = styled(HillInactive)`
  position: absolute;
  left: 0px;
  bottom: -35px;
  width: 10%;
`;
export const StyledHillInactive = styled(HillInactive)`
  position: absolute;
  left: 0px;
  bottom: -35px;
  width: 10%;
`;

export const StyledCastle = styled(Castle)`
  position: absolute;
  bottom: -40px;
  right: 22%;
  width: 8%;
`;
export const StyledCastleInactive = styled(CastleInactive)`
  position: absolute;
  bottom: -40px;
  right: 22%;
  width: 8%;
`;


export const StyledCityInactive = styled(CityInactive)`
  position: absolute;
  bottom: -10px;
  left: 15%;
  width: 15%;
`;
export const StyledCity = styled(City)`
  position: absolute;
  bottom: -10px;
  left: 15%;
  width: 15%;
`;

export const StyledBoat = styled(Boat)`
  position: absolute;
  bottom: -10px;
  left: 25%;
  width: 15%;
`;

export const StyledSea = styled(Sea)`
  position: absolute;
  bottom: -57px;
  left: 23%;
`;

export const StyledLighthouseInactive = styled(LighthouseInactive)`
  position: absolute;
  bottom: 25px;
  left: 30%;
`;
export const StyledLighthouse = styled(Lighthouse)`
  position: absolute;
  bottom: 25px;
  left: 30%;
`;

export const StyledMountainInactive = styled(DesertInactive)`
  position: absolute;
  bottom: -27px;
  right: 0px;
  width: 12%;
`;
export const StyledMountain = styled(Mountain)`
  position: absolute;
  bottom: -27px;
  right: 0px;
  width: 12%;
`;
export const StyledFarmInactive = styled(FarmInactive)`
  position: absolute;
  bottom: 31px;
  right: 35%;
  width: 10%;
`;
export const StyledFarm = styled(Farm)`
  position: absolute;
  bottom: 31px;
  right: 35%;
  width: 10%;
`;

export const StyledFactoryLarge = styled(Factory)`
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 40%;
  max-width: 700px;
`;
export const StyledFactory = styled(Factory)`
  position: absolute;
  bottom: 0px;
  left: 50%;
  width: 18%;
  transform: translate(-50%, 0) scaleX(-1);
`;
export const StyledFactoryInactive = styled(FactoryInactive)`
  position: absolute;
  bottom: 0px;
  left: 50%;
  width: 18%;
  transform: translate(-50%, 0) scaleX(-1);
`;

export const StyledSky = styled(Sky)`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100vw;
`;

export const StyledSky2 = styled(Sky2)`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100vw;
`;
export const StyledSky2Inactive = styled(Sky2Inactive)`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100vw;
`;

export const StyledSky3Inactive = styled(Sky3Inactive)`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100vw;
`;
export const StyledSky4Inactive = styled(Sky4Inactive)`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100vw;
`;
export const StyledSky5Inactive = styled(Sky5Inactive)`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100vw;
`;
export const StyledSky6Inactive = styled(Sky6Inactive)`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100vw;
`;
export const StyledSky7Inactive = styled(Sky7Inactive)`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100vw;
`;

export const StyledSky8Inactive = styled(Sky8Inactive)`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100vw;
`;
export const StyledSky3 = styled(Sky3)`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100vw;
`;

export const StyledSky4 = styled(Sky4)`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100vw;
`;

export const StyledSky5 = styled(Sky5)`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100vw;
`;

export const StyledSky6 = styled(Sky6)`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100vw;
`;

export const StyledSky7 = styled(Sky7)`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100vw;
`;

export const StyledSky8 = styled(Sky8)`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100vw;
`;

export const StyledSky9 = styled(Sky9)`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100vw;
`;

export const StyledTruck = styled(Truck)<{gone?: boolean}>(
  ({ gone }) => css `
  transform: scaleX(-1);
  width: 8%;
  position: absolute;
  left: calc(50% - 22%);
  bottom: 0px;
  animation: ${gone ? "go-away 2.5s ease-in forwards" : ""};
  @keyframes go-away {
    from {
      left: calc(50% - 22%);
    } to {
      left: -15%;
    }
  }
`)
export const StyledTruck2 = styled(Truck2)<{gone?: boolean}>(
  ({ gone }) => css`
  transform: scaleX(-1);
  width: 8%;
  position: absolute;
  left: calc(50% + 5%);
  bottom: 0px;
  animation: ${gone ? "go-away-2 3s ease-in forwards" : ""};
  @keyframes go-away-2 {
    from {
      left: calc(50% + 5%);
    } to {
      left: 105%;
    }
  }
`)
export const StyledFactoryLine = styled(FactoryLine)`
  position: absolute;
  bottom: 0px;
  width: 7%;
  left: calc(50% - 14%);
  transform: scaleX(-1);
`;

export const StyledSmoke = styled(Smoke)`
  position: absolute;
  bottom: 20%;
  width: 7%;
  left: calc(50% - 14%);
  transform: scaleX(-1);
`;

export const StyledSun = styled(Sun)<{ hour: number }>(
  ({ hour }) => css`
    position: absolute;
    bottom: 20%;
    width: 7%;

    animation: riseX 5s infinite;
    :after {
      animation: riseY 5s;
    }

    @keyframes riseX {
      from {
        transform: translateX(0);
      }
      to {
        transform: translateX(20vw);
      }
    }
    @keyframes riseY {
      from {
        transform: translateY(0);
      }
      to {
        transform: translateY(40vy);
      }
    }
  `
);

export const StyledBoxContainer = styled.div`
  position: absolute;
  bottom: 0px;
  left: calc(50% - 10%);
  width: 8%;

  .box {
    position: absolute;
    animation: move 3s infinite linear;
    z-index: 1;
    bottom: 0px;
    width: 20%;
    transform: translate(20%);

    &.second-box {
      animation-delay: 1.5s;
    }
  }

  @keyframes move {
    0% {
      opacity: 0;
      transform: translate(20%);
    }
    20% {
      transform: translate(-80%);
      opacity: 1;
    }
    60% {
      opacity: 1;
      transform: translate(-250%);
    }
    65% {
      opacity: 0;
      transform: translate(-250%);
    }
    100% {
      opacity: 0;
      transform: translate(-250%);
    }
  }
`;

export const StyledCloudContainer = styled.div`
  @keyframes animate {
    0% {
      opacity: 0;
    }
    40% {
      opacity: 0;
      transform: translate(0px, 0px);
    }
    80% {
      opacity: 1;
      transform: translate(53px, -45px);
    }
    100% {
      opacity: 0;
      transform: translate(106px, -99px);
    }
  }
`;

const animateCloud = keyframes`
  0% {
    opacity: 0;
    transform:translateX(0);
  }
  33% {
    opacity: 1;
  }
  100% {
    transform:translateX(30px)
  }
`;

export const StyledCloudContainerWelcome = styled.div`
  position: absolute;
  right: 0;
  animation: ${animateCloud} 7s forwards ease-in-out;
`;

const animateTitle = keyframes`
  0% {
    opacity: 1;
    transform: scale(0);
  }
  5% {
    transform: scale(0);
  }
  60% {
    transform: scale(2.5);
  }
  100% {
    opacity: 1;
    transform: scale(2);
  }
`;
export const StyledTitle = styled.div`
  opacity: 0;
  animation: ${animateTitle} 2s forwards ease-in-out;
  animation-delay: 1s;
`;

const animateTruck = keyframes`
  0% {
    left: 1%;
  }
  20% {
    opacity: 1;
  }
  50% {
    left: 40%;
  }
  100% {
    opacity: 1;
    left: 100%;
  }
`;
export const StyledGreenTruck = styled(GreenTruck)`
  position: absolute;
  opacity: 0;
  bottom: -70px;
  left: 1%;
  width: 30%;
  animation: ${animateTruck} 5s infinite ease-in-out;
  animation-delay: 3s;
`;

export const LoadingGreenTruck = styled(GreenTruck)`
  position: absolute;
  opacity: 1;
  width: auto;
  animation: ${animateTruck} 5s infinite ease-in-out;
  animation-delay: 3s;
`;
