import React from "react";
import { LoadingGreenTruck } from "@components/illustrations/Illustrations";

const Loading = () => (
  <div className="absolute h-screen w-full bg-grey-light opacity-80 flex items-center justify-center">
    <LoadingGreenTruck />
  </div>
);

export default Loading;
