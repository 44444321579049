import React from "react";
import classNames from "classnames";
import { Icon } from "@metyis-ds/icon";
import { Text1Light } from "@metyis-ds/typography";
import content from "./ContentLayout.content";
import Loading from "@components/loading/Loading";

interface IContentLayoutProps {
  header?: JSX.Element;
  hideHeader?: boolean;
  children: JSX.Element[] | JSX.Element;
  onBackClick?: () => void;
  loading?: boolean;
}
const ContentLayout: React.FC<IContentLayoutProps> = ({
  children,
  header,
  onBackClick,
  hideHeader,
  loading
}) => {
  const mainContentClasses = "px-10 xl:px-20 overflow-auto";

  const headerEl = hideHeader ? (
    <header className="flex items-center" />
  ) : (
    <header
      className={classNames("flex items-center px-10 xl:px-20 h-[100px]")}
    >
      {onBackClick && (
        <span
          className="flex items-center cursor-pointer"
          onClick={onBackClick}
        >
          <Icon name="arrow-left" height={24} width={24} />
          <Text1Light className="ml-xs">{content.back}</Text1Light>
        </span>
      )}
      {header}
    </header>
  );
  return (
    <section
      className={classNames(
        "grid grid-flow-row overflow-hidden h-screen relative",
        Array.isArray(children) ? "grid-rows-base" : "grid-rows-header"
      )}
    >
      {headerEl}
      {Array.isArray(children) ? (
        <>
          <section className={classNames(mainContentClasses)}>
            {children[0]}
          </section>
          {children[1]}
        </>
      ) : (
        <section className={classNames(mainContentClasses)}>{children}</section>
      )}
      {loading && <Loading />}
    </section>
  );
};

export default ContentLayout;
